.not_found_block{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.main_text{
    font-size: 192px;
    font-weight: 900;
    line-height: 192px;
    color: #ECECEF;
}
.description_cont{
    width: 328px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
}
.description_text_1{
    font-size: 48px;
    font-weight: 500;
    line-height: 48px;
}
.description_text_2{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
}