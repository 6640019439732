@import "./normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

:root {
    --black: #202945;
    --blue: #2196F3;
    --gray: #DEE0F7;
    --light-gray: #FBFBFD;
    --white: #FFFFFF;
    --dark-gray: #7F869F;
    --background: #FAFAFF;
    --href: #5B627A;
    --dark-blue: #2E39B3;
    --padding-btn: #F1F3FF;
    --green: #8CC63E;
    --green-background: rgba(118, 158, 42, 0.10);
    --ads-background: #444E71;
    --sh-header-bg: #0F2C52;
    --ads-gray: #A1A1A1;
    --main-black-color: #3A3F48;
    --main-purple-color: #538DFE;
    --main-purple-hover-color: #E7EEF9;
    --main-bg-color: rgba(244, 246, 249, 1);
    --main-light-text-color: rgba(0, 0, 0, 0.60);
    --main-divider-color: rgba(0, 0, 0, 0.40);
    --main-grey-color: rgba(0, 0, 0, 0.40);
    --form-max-width: 740px;
    --background-color: #F4F6F9;
    --table-th-bg: #F7F0FF;
    --button-background-color: linear-gradient(90deg, #90CAF9 0%, #3378FF 100%, #047EDF 100%);

}

* {
    font-family: 'Roboto', sans-serif !important;
}

a {
    text-decoration: none !important;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bold-row {
    font-weight: 600;
}

.ql-container{
    background: rgba(0, 0, 0, 0.06);
    border: none !important;
    border-bottom: 1px solid #666666 !important;
}

.ql-toolbar{
    border-radius: 4px 4px 0 0;
    border: none !important;
    border-bottom: 1px solid #D2D2D2 !important;
    background: rgba(0, 0, 0, 0.06);
}