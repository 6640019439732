.input-file {
  cursor: pointer;
  width: 100%;
  height: 42px;
  position: relative;
  display: inline-block;
  background: #1976d2;
  border-radius: 4px;
}
.input-file-text {
  overflow: hidden;
  margin-right: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 74%;
  padding: 0 10px;
  line-height: 40px;
  text-align: left;
  height: 40px;
  display: block;
  float: left;
  box-sizing: border-box;
  border-radius: 6px 0px 0 6px;
  border: 1px solid #ddd;
  border-right: none;
}
.input-file-btn {
  position: absolute;
  top: 25%;
  left: 40%;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}
.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
